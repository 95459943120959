<template>
  <Banner bg-image="green-bg.jpg" talk-img="suicide-risk-factor.png">
    <template v-slot:title> Help for suicidal intentions</template>
    <template v-slot:subtitle>
      Your desire to die may be strong because you might be feeling so upset,
      angry and in pain that you believe these feelings will never end. Feeling
      vulnerable, isolated and overwhelmed can be distressing. If you have the
      intention to act on your thoughts of suicide, you don’t need to act on
      your thoughts right now.
    </template>
    <template v-slot:description>
      Let’s learn about the steps you can take right now to stop yourself from
      acting on your suicidal thoughts.</template
    >
  </Banner>

  <section class="my-6 bg-white md:my-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        First things first: Try to focus on just getting through today and not
        the rest of your life.
      </div>
      <p>
        You may have had these thoughts and feelings before, but you feel less
        able to cope today. It is important to remember that feelings cannot and
        will not last. Like all feelings, these ones will pass.
      </p>
    </div>
  </section>

  <section class="my-6 bg-white md:my-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-xl call-text md:text-2xl text-blue">
        If you feel unable to keep yourself safe, it’s a mental health
        emergency.
      </div>
    </div>
  </section>

  <!-- Quick Access section  -->
  <section class="pt-8 mt-8 md:mt-14 md:pb-8 gradient-white">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Get emergency help
      </div>
      <div class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6">
        <router-link :to="{ name: 'CallACrisisHotline' }">
          <QuickAccess
            arrow="text-green"
            text="Call a crisis hotline"
            img="call-a-crisis-hotline.svg"
          />
        </router-link>
        <router-link :to="{ name: 'FindACounselor' }">
          <QuickAccess
            arrow="text-yellow"
            text="Find a counselor"
            img="find-a-counselor.svg"
          />
        </router-link>
      </div>
      <div class="mt-10">
        <div class="mb-6 text-xl text-center call-text md:text-2xl text-blue">
          If you can keep yourself safe, there are ways to help yourself.
        </div>
      </div>
    </div>
  </section>

  <!-- Help Myself  -->
  <section class="talk-sect md:my-12 md:py-1s4">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div
          class="text-2xl font-bold py-9 md:mb-6 call-text md:text-3xl text-blue"
        >
          How can I help myself now?
        </div>
        <p>
          There are steps you can take right now to stop yourself from acting on
          your suicidal thoughts. These are some practical tips that other
          people have found helpful when they’ve felt suicidal.
        </p>
        <p class="mt-10 text-blue">
          Remember: Everyone is different, so it’s about finding what works best
          for you.
        </p>
      </div>

      <div class="pt-8">
        <!-- 1 -->
        <Collapsible>
          <template v-slot:header>
            <span class="px-8 text-2xl"> Stay safe now</span>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Get through the next five minutes
                </div>
                <p>
                  Taking things minute by minute can help make things more
                  bearable. Reward yourself each time five minutes have passed.
                </p>
                <p>
                  Use our
                  <router-link
                    class="underline text-green"
                    :to="{ name: 'PracticeGroundingTechniques' }"
                    >grounding techniques</router-link
                  >
                  or practical ideas to help you through the next few minutes.
                </p>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Remove anything you could use to harm yourself
                </div>
                <p>
                  Remove any items or things you could use to harm yourself or
                  ask someone else to remove these for you. If you’re in an
                  unsafe location, move away to somewhere safer.
                </p>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Follow your safety plan or crisis plan
                </div>
                <p>
                  If you have a
                  <router-link
                    class="underline text-green"
                    :to="{ name: 'CreateASafetyPlan' }"
                  >
                    safety plan
                  </router-link>
                  in place, follow it.
                </p>
                <p>
                  These plans are a good way to save ideas for helping yourself
                  when you feel unwell.
                </p>
                <div class="mt-6 text-right">
                  <router-link
                    class="font-bold text-green"
                    :to="{ name: 'CreateASafetyPlan' }"
                  >
                    Learn how to create a safety plan
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Tell someone how you’re feeling
                </div>
                <p>
                  Whether it’s a friend, family member or even a pet, telling
                  someone else how you’re feeling can help you feel less alone
                  and more in control.
                </p>
                <div class="my-6 text-right">
                  <router-link
                    class="font-bold text-green"
                    :to="{ name: 'LearnHowToAskForHelp' }"
                  >
                    Learn tips for talking to someone
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Go to a safe place
                </div>
                <p>
                  Go to a place where you feel safe. Here is a list of places
                  you could try:
                </p>
                <ul class="about-ul">
                  <li>Mental health or spiritual center</li>
                  <li>Crisis center</li>
                  <li>A friend’s or relative’s house</li>
                  <li>Library</li>
                </ul>
                <div class="my-6 text-right">
                  <router-link
                    class="font-bold text-green"
                    :to="{ name: 'CallACrisisHotline' }"
                  >
                    Contact information for crisis centers
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <span class="px-8 text-2xl">Distract yourself </span>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Try some coping techniques
                </div>
                <p>
                  If have a strong desire or intention to harm yourself, you
                  could try using self-harm coping techniques. For example, you
                  could:
                </p>
                <ul class="my-6 about-ul">
                  <li>Tear something up into hundreds of pieces.</li>
                  <li>
                    Take a very cold shower or bath, while playing your favorite
                    songs.
                  </li>
                  <li>Draw or paint.</li>
                  <li>Listen to music.</li>
                  <li>Sing like no one is listening.</li>
                  <li>Dance like no one is watching.</li>
                </ul>
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Practice Indigenous cultural healing practices
                </div>
                <p>
                  Perform dances, ceremonies, and spiritual practices, such as
                  powwows, sweetgrass ceremonies, sweat lodges and drumming
                  circles.
                </p>
                <div class="my-6 text-right">
                  <router-link
                    @click="
                      this.$gtm.trackEvent({
                        event: 'gtm.btnClick',
                        category: 'button',
                        btnTitle: 'ExploreCulturalHealingPractices-btn',
                        label: 'Learn more about cultural practices',
                        action: 'Clicked on',
                      })
                    "
                    :to="{ name: 'ExploreCulturalHealingPractices' }"
                    class="font-bold text-green"
                  >
                    Learn more about cultural practices
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Focus on breathing
                </div>
                <p>
                  Take long, deep breaths. Breathing out for longer than you
                  breathe in can help you feel calmer.
                </p>
                <div class="my-6 text-right">
                  <router-link
                    class="font-bold text-green"
                    :to="{ name: 'PracticeBreathingExercises' }"
                  >
                    Try breathing exercises
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Focus on your sense of smell
                </div>
                <p>
                  Go to a coffee shop, a soap shop, perfume shop, or turn on a
                  diffuser with scented oils like lavender, tea tree or sage
                  oil. A scented candle would also work.
                </p>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Focus on your sense of taste
                </div>
                <p>
                  Treat yourself to a food you like and pay close attention to
                  how it tastes, how it feels in your mouth and what you like
                  about it.
                </p>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Look after your needs
                </div>
                <p>If you can, try doing the following:</p>
                <ul class="about-ul">
                  <li>Get a glass of water.</li>
                  <li>Eat something.</li>
                  <li>
                    Name the emotions you are feeling at the moment and allow
                    yourself to feel what you are feeling.
                  </li>
                </ul>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Go outside
                </div>
                <p>
                  If you’re feeling numb, go outside and feel the rain, sun or
                  wind against your skin. This can help you to feel more
                  connected to your body.
                </p>
                <ul class="about-ul">
                  <li>Walk in a green space like a park.</li>
                  <li>Listen to nature.</li>
                </ul>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Avoid substances that can make you feel worse
                </div>
                <p class="pb-6">
                  Try to avoid drinking alcohol or taking recreational drugs, as
                  this can make you feel worse.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <span class="px-8 text-2xl">Challenge your thoughts</span>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-6 text-2xl font-bold call-text md:text-3xl">
                  Understand your thought patterns
                </div>
                <p>
                  There are many different types of thinking patterns we might
                  use when we have a desire to die. Here are some ways to
                  understand or even interrupt these patterns:
                </p>
                <ul class="about-ul">
                  <li>
                    Practice a mindfulness activity like
                    <router-link
                      class="underline text-green"
                      :to="{ name: 'PracticeGuidedMeditation' }"
                    >
                      guided meditation</router-link
                    >,
                    <router-link
                      class="underline text-green"
                      :to="{ name: 'PracticeBreathingExercises' }"
                    >
                      breathing exercises</router-link
                    >

                    or yoga.
                  </li>
                  <li>
                    Start a
                    <a
                      href="/files/thought-diary.pdf"
                      download
                      class="underline text-green"
                      >thought diary</a
                    >.
                  </li>
                  <li>Focus on things you are grateful for.</li>
                  <li>
                    Seek professional help. Here are some
                    <router-link
                      class="underline text-green"
                      :to="{ name: 'FindSomeoneToTalkTo' }"
                    >
                      professionals</router-link
                    >
                    you can contact.
                  </li>
                  <li>
                    Write down what you’re looking forward to. You might look
                    forward to eating your favourite meal, seeing a loved one or
                    catching up on the next episode of a TV show.
                  </li>
                  <li>
                    Make plans to do something you enjoy today, tomorrow or in
                    the near future. Plans don’t have to be big or expensive.
                  </li>
                </ul>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Be kind to yourself
                </div>
                <p>
                  Talk to yourself as if you were talking to a good friend. And
                  try doing something nice for yourself. This could even be
                  something small like:
                </p>
                <ul class="about-ul">
                  <li>Have a bath.</li>
                  <li>
                    Wrap yourself in a blanket and watch your favourite film.
                  </li>
                </ul>
                <p>
                  These ideas may seem silly but it can be easy to forget to do
                  something nice for yourself.
                </p>
                <div class="my-6 text-2xl font-bold call-text md:text-3xl">
                  Write it down
                </div>
                <p>
                  It can help to write down your thoughts when you’re feeling
                  negative. The key is to find a way that works for you. Whether
                  that’s jotting them down in a notebook or recording notes on
                  your phone. It’s like you’re transferring your thoughts out of
                  your head and into a journal. When making notes, try to put
                  down your thoughts under three headings:
                </p>
                <ul class="about-ul">
                  <li>What’s the situation?</li>
                  <li>What am I thinking or telling myself?</li>
                  <li>How down/anxious or bad do I feel on a scale of 1–10?</li>
                </ul>
                <div class="my-6 text-right">
                  <router-link
                    class="font-bold text-green"
                    :to="{ name: 'ChallengeYourThoughts' }"
                  >
                    Practice challenging your thoughts
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>
        <br />
      </div>
    </div>
  </section>

  <FooterCard img="stay-safe.png" text="Thoughts" name="Thoughts">
    <template v-slot:header> Stay safe and learn more </template>
    <template v-slot:content>
      To provide the most appropriate support and stay safe you can learn more
      about suicide intent or learn how to get help when having a plan for
      suicide.
    </template>
    <template v-slot:btn>
      <GreenBtn name="PlanningSuicide" text="Plan" />
    </template>
  </FooterCard>
</template>
<script>
import Banner from "@/components/Banner";
import QuickAccess from "@/components/QuickAccess";
import Collapsible from "@/components/Collapsible";
import GreenBtn from "@/components/GreenBtn";
import FooterCard from "@/components/FooterCard";
export default {
  name: "Intent",
  components: {
    Banner,
    QuickAccess,
    Collapsible,
    GreenBtn,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
ul.about-ul li {
  padding: 8px 0 8px 50px;
  position: relative;
}
ul.about-ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
